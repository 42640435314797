import apibase from './BaseAPI';
import lib from "../businesses/lib";

const search = async (agentId, search, paging) => {
    const params = {
        agentId: agentId,
        search: search,
        paging: paging,
    };
    const url = '/properties/search';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};


const searchHome = async (search, paging, propertyFor, type, beds) => {
    let params = {};
    if (paging) params.paging = paging;
    if (search) params.search = search;
    // if(propertyFor) params.propertyFor = propertyFor;
    if (propertyFor) {
        if (propertyFor === "newLaunch") { params.propertyListing = "Projects" }
        else if (propertyFor === "Commercial") { params.categoryListing = "Commercial" }
        else params.propertyFor = propertyFor;
    }
    if (type) params.type = type;
    if (beds) params.beds = beds;
    lib.log(params)
    const url = '/properties/web/search';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};


const searchHomeLatest = async () => {
    const url = '/properties/web/searchLatest';
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
}

const webQuickSearch = async (search, propertyFor) => {
    let params = {};
    if (search) params.search = search;
    if (propertyFor) params.propertyFor = propertyFor;

    const url = '/properties/web/quickSearch';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const countSearch = async (agentId) => {
    const params = {
        agentId: agentId
    };
    const url = '/properties/countSearch';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const totalBuyRent = async (agentId, val, area, active) => {
    const params = {
        agentId: agentId,
        search: val,
        area: area,
        active: active
    };
    const url = '/properties/totalBuyRent';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const id = async (id) => {
    const url = `/properties/${id}`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const countries = async () => {
    const url = `/properties/countries`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const categories = async () => {
    const url = `/properties/categories`;
    try {
        const result = await apibase.post(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const webRevampQuickSearch = async (search, target) => {
    let params = {};
    if (search) params.search = search;
    if (target) params.target = target;

    const url = '/properties/webRevamp/quickSearch';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const getFilter = async () => {
    const url = `/properties/web/getSeoFilter`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const webRevampSearch = async (search, paging) => {
    let params = {};
    if (paging) params.paging = paging;
    if (search) params.search = search;
    lib.log('webRevampSearch');
    lib.log(params);
    const url = '/properties/webRevamp/search';
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const getAdvertisement = async (search, paging) => {
    let params = {};
    if (search) params.search = search;
    if (paging) params.paging = paging;

    const res = await apibase.post(`/iklan/search`, params);
    return res.data;
};

const getWebsiteBanner = async () => {
    const url = `/iklan/websiteBanner`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const similarProperties = async (propertyId) => {
    const url = `/properties/${propertyId}/similarProperties`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const otherProperties = async (propertyId) => {
    const url = `/properties/${propertyId}/otherProperties`;
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
};

const otherSearches = async (addressState, addressArea) => {
    const queryParams = new URLSearchParams({ addressState, addressArea }).toString();

    const url = `/properties/otherSearches?${queryParams}`
    try {
        const result = await apibase.get(url);
        return result.data;
    } catch (err) {
        throw err;
    }
}

const moreProperties = async (pfor, countryOrState) => {
    const url = `/properties/${lib.toTitleCase(pfor)}/${countryOrState}/more`;
    try {
        const result = await apibase.get(url);
        return result.data[0];
    } catch (err) {
        throw err;
    }
}

const agentOtherProperties = async (agentId, propId) => {
    const params = {
        agentId: agentId,
        propId: propId,
    }
    const url = `/properties/agentOtherProperties`;
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
}

const aiSearchProperties = async (msg) => {
    const url = '/properties/propertyAiSearch';
    const params = {
        msg: msg,
        limit: 40, // Manual set the limit
    }
    try {
        const result = await apibase.post(url, params);
        return result.data;
    } catch (err) {
        throw err;
    }
}

export default {
    search: search,
    webQuickSearch: webQuickSearch,
    searchHomeLatest: searchHomeLatest,
    searchHome: searchHome,
    countSearch: countSearch,
    totalBuyRent: totalBuyRent,
    id: id,
    countries: countries,
    categories: categories,
    webRevampQuickSearch: webRevampQuickSearch,
    getFilter: getFilter,
    webRevampSearch: webRevampSearch,
    getAdvertisement: getAdvertisement,
    getWebsiteBanner: getWebsiteBanner,
    similarProperties: similarProperties,
    otherProperties: otherProperties,
    otherSearches: otherSearches,
    moreProperties: moreProperties,
    agentOtherProperties,
    aiSearchProperties,
};