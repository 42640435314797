import React, { useState, useEffect, Fragment } from "react";
import SetupData from "../data/SetupData";
import { trackPromise } from "react-promise-tracker";
import seoComponent from "../businesses/seoComponent";
import featurePropertyComponent from "../businesses/FeaturePropertyComponent";
import lib from "../businesses/lib";
import Avatar from "../components/Avatar";
import Footer2 from "../components/Layout/Footer2";
import Header2 from "../components/Layout/Header2";
import { Card, CardImg, Container } from "reactstrap";
import MortgageCalculator from "../components/MortgageCalculator";
import "../styles/pages/MortgageCalculatorPage.scss";
import locIcon from "../assets/img/pin_rounded_circle.svg"
import Carousel from "react-elastic-carousel";
import house from "../assets/img/housegrey.svg";
import car from "../assets/img/cargrey.svg";
import shower from "../assets/img/bathgrey.svg";
import bed from "../assets/img/bedgrey.svg";
import landGreyIcon from "../assets/img/icon_land_grey.svg";
import { useTranslation } from 'react-i18next';
import PropertyCard from "../components/Layout/PropertyCard";

const MortgageCalculatorPage = (props) => {
  const { t } = useTranslation();
  const [featureProperties, setfeatureProperties] = useState([]);
  const breakPoints = [
    { width: 1, itemsToShow: 1, itemsToScroll: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3, itemsToScroll: 3 },
    { width: 1200, itemsToShow: 4, itemsToScroll: 4 },
  ];

  useEffect(() => {
    window.scrollTo(0, 0);
    const getData = async () => {
      const activeFeatured = await trackPromise(featurePropertyComponent.webSearch());
      setfeatureProperties(activeFeatured.data);
      lib.log(activeFeatured.data);
    };
    getData();
  }, []);

  const handlePopulateData = (property) => {
    const newUrl = seoComponent.createPropertyDetailUrl(property);
    return newUrl;
  };

  const mortgageCalculatorInfo = (
    <Container className="mortgage-cal-wrapper">
      <section id="mortgage-calculator" className="mortgage-cal-section" />
      <Card className="mortgage-cal-card">
        <h1>Home Loan Analyzer</h1>
        <h2>How to Use Our Home Loan Eligibility Calculator in Malaysia:</h2>
        <h4>You can calculate your estimated monthly repayment by entering your property price, loan term(years), down payment, and interest rate.</h4>
        <MortgageCalculator data={props.history?.location?.state} />
        <h4>Note: A revision in the <a className="mortgage-cal-link" href="https://www.bnm.gov.my/" target="_blank" rel="noopener noreferrer nofollow">Overnight Policy Rate (OPR)</a> will cause an increase in the cost of borrowing for financial institutions. Therefore, home loans or mortgages will be more costly for borrowers.</h4>
        <h4>Maximum Loan Amount = 90% of the property price unless stated otherwise.</h4>
        <h4>For more information or enquiries, <a className="mortgage-cal-link" href="https://nextsix.com/contact" target="_blank" rel="noopener noreferrer">please contact our property agents</a> using our GPS Your Agent application, or email us at <a className="mortgage-cal-link" href="mailto:hello@nextsix.com">hello@nextsix.com</a></h4>
      </Card>
    </Container>
  );

  const featurePropertyInfo = (
    featureProperties.length > 0 && (
      <Container className="property-row-wrapper2">
        <div className="property-row-content2">
          <Fragment>
            <div className="property-row-headerRow2">
              <p className="property-row-header2">{t('feature-listing')}</p>
            </div>
            <Carousel
              breakPoints={breakPoints}
              enableSwipe={true}
              showArrows={true}
              enableAutoPlay={false}
              pagination={false}
              className="property-row-carousel2">
              {featureProperties.map((properties, idx) => {
                let property = properties.property;
                return (
                  <a href={handlePopulateData(property)} className="property-row-redirectLink2 property-row-redirectLink2-v2 property-slider" key={idx}>
                    <PropertyCard property={property} type="properties" loc="homePage" showAgent={true} />
                  </a>
                  // <a href={handlePopulateData(property)} className="property-row-redirectLink2 property-row-redirectLink2-v2 property-slider" key={idx}>
                  //                       <Card className={`property-list-card-body property-list-home-page ${property.isBestDeal ? "" : ""}`}
                  //     style={{ cursor: "pointer" }}
                  //     title={`View ${property.name}`}
                  //   // onClick={() => handlePopulateData(property)}
                  //   >
                  //     <CardImg
                  //       className="property-list-cardImg2"
                  //       src={
                  //         property.images && property.images.length > 0
                  //           ? property.images[0]
                  //           : ""
                  //       }
                  //       alt={property && (property.for == "Rent" || property.for == "Buy") && (property.type + " " + (property.for == "Rent" ? "For Rent" : "For Sale") + " in " + property.address?.area + " " + property.address?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                  //     />
                  //     <div className="property-list-card-row-body2">
                  //       {/* unset justifyContent to aovid alignment changes  */}
                  //       <div className="property-row-tagGroup2">
                  //         <div className="property-row-saleTag2-wrapper">
                  //           <div className={`property-row-saleTag2 ${property.for.toLowerCase()}`}>
                  //             {property.for === "Buy" ? t('sale') : t('rent')}
                  //           </div>
                  //           {property.rentType && <div className="property-row-saleTag2 rent-type">
                  //             {property.rentType}
                  //           </div>}
                  //         </div>
                  //         {/* <div className="property-row-createdTag2">
                  //           {`${t('last-updated-on')} ` + moment(property.created).format("DD/MM/YYYY")}</div> */}
                  //       </div>
                  //       <div className="property-row-locGroup2">
                  //         <img loading="lazy" src={locIcon} className="locIcon2" alt="" />
                  //         <p className="property-row-locText2">{property.address.area}, {property.address.state}</p>
                  //       </div>
                  //       {property.for && property.for.toLowerCase() === "rent" && (
                  //         <div className="property-row-priceText2">
                  //           {`RM ${property.rent.monthlyRental.toLocaleString()}/${t('month')}`}
                  //         </div>
                  //       )}
                  //       {property.for && property.for.toLowerCase() === "buy" && (
                  //         <div className="property-row-priceText2">
                  //           RM {property.buy.price.toLocaleString()}
                  //         </div>
                  //       )}
                  //       <div className="property-row-nameText2">
                  //         {property.name}&nbsp;
                  //       </div>
                  //     <div className="property-row-iconsGroup2-wrapper">
                  //       {property.beds && (
                  //         <span className="property-row-iconsGroup2">
                  //           <img loading="lazy" src={bed} alt="" className="property-row-iconImg2" />
                  //           <span className="property-row-iconText2">{property.beds}
                  //           </span></span>
                  //       )}
                  //       {property.baths && (
                  //         <span className="property-row-iconsGroup2">
                  //           <img loading="lazy" src={shower} alt="" className="property-row-iconImg2" />
                  //           <span className="property-row-iconText2">{property.baths}</span>
                  //         </span>
                  //       )}
                  //       {property.carParks != 0 && property.carParks && (
                  //         <span className="property-row-iconsGroup2">
                  //           <img loading="lazy" src={car} alt="" className="property-row-iconImg2" />
                  //           <span className="property-row-iconText2">{property.carParks}</span>

                  //         </span>
                  //       )}
                  //       {(() => {
                  //         if (property.title === 'Agricultural Land' || property.title === 'Commercial Land'
                  //           || property.title === 'Industrial Land' || property.title === 'Residential Land') {
                  //           if (property.landArea) {
                  //             return <span className="property-row-iconsGroup2">
                  //               <img loading="lazy" src={landGreyIcon} alt="" className="property-row-iconImg2" />
                  //               <span className="property-row-iconText2">{property.landArea} sqft</span>
                  //             </span>
                  //           }
                  //         } else {
                  //           if (property.buildup) {
                  //             return <span className="property-row-iconsGroup2">
                  //               <img loading="lazy" src={house} alt="" className="property-row-iconImg2" />
                  //               <span className="property-row-iconText2">{property.buildup} sqft</span>
                  //             </span>
                  //           }
                  //         }
                  //       })()}
                  //     </div>
                  //     </div>
                  //     {property.agent && (
                  //       <>
                  //         <div className="property-row-footer2">
                  //           <div className="property-row-footerGroup2">
                  //             <Avatar
                  //               className="property-row-footerAvatar2"
                  //               src={
                  //                 property.agent.image
                  //                   ? property.agent.image
                  //                   : SetupData.profile.imgURL
                  //               }
                  //               alt={property.agent && property.agent.area && property.agent.state && ((property.agent.nickname ? property.agent.nickname : property.agent.name) + " Property Agent in " + property.agent?.area + " " + property.agent?.state.replace(`Wilayah Persekutuan`, ``).trim())}
                  //             />
                  //             <div className="property-row-footerAgentInfo2">
                  //               <span className="property-row-footerCompany2">{property.agent.companyName && property.agent.renId && property.agent.renId !== "Private Advertiser" ? property.agent.companyName : "Private Advertiser"}</span>
                  //               <span className="property-row-footerAgentName2">{property.agent.nickname || property.agent.name}</span>
                  //             </div>
                  //           </div>
                  //         </div>
                  //       </>
                  //     )}
                  //   </Card>
                  // </a>
                );
              })
              }
            </Carousel>
          </Fragment>
        </div>
      </Container>
    )
  );
  return (
    <>
      <Header2 />
      <div className="property-listing">
        {mortgageCalculatorInfo}
        {featurePropertyInfo}
      </div>
      <Footer2 />
    </>
  );
}

export default MortgageCalculatorPage;