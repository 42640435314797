import qs from 'qs';
import SetupData from "../data/SetupData";

import string from "../businesses/string";
import global from "../businesses/global";
import seoComponent from "../businesses/seoComponent";

// NXT-[2357] Switching between buy/rent/property/agent
// Modify into component, use in propertyListV2 and Header2
export const decodeUrlLocationV2 = ({ location, useParams, titleOptions, auctionTitleOptions }) => { //decode url to show search filter values
    const {
        state_or_title_or_type,
        state,
        area_or_title_or_type,
        area,
        title_or_type,
        ai,
    } = useParams;

    let target;
    const pathname = location.pathname;
    const queryParams = qs.parse(location.search?.slice(1));

    if (pathname.startsWith(`/properties-for-sale`) || pathname.startsWith(`/ai-properties-for-sale`)) target = `properties-for-sale`;
    else if (pathname.startsWith(`/properties-for-rent`) || pathname.startsWith(`/ai-properties-for-rent`)) target = `properties-for-rent`;
    else if (pathname.startsWith(`/projects-for-sale`)) target = `projects-for-sale`;
    else if (pathname.startsWith(`/auction-properties-for-sale`)) target = `auction-properties-for-sale`;
    else target = `properties-for-sale`;

    let urlSearch = { target };

    if (ai) { //decode ai search url for /ai-properties-for-sale or /ai-properties-for-rent
        let selectedAiSearch = seoComponent.seoToState(ai);
        urlSearch.aiSearchDisplay = string.capitalize(selectedAiSearch);
        let searchContext = {};
        if (queryParams.area) {
            let selectedArea = seoComponent.treatAreaQueryParam(queryParams.area);
            searchContext.area = Array.isArray(selectedArea) ? selectedArea : [selectedArea];
        }

        if (queryParams.state) {
            let selectedState = seoComponent.treatStateQueryParam(queryParams.state);
            searchContext.state = Array.isArray(selectedState) ? selectedState : [selectedState];
        }

        if (queryParams.title) {
            let selectedTitle = seoComponent.treatTitleQueryParam(queryParams.title);
            searchContext.title = Array.isArray(selectedTitle) ? selectedTitle : [selectedTitle];
        }

        if (queryParams.master) {
            let selectedMaster = seoComponent.decodeQueryParam(queryParams.master);
            searchContext.master = (selectedMaster === 'true');
        }

        if (Object.keys(searchContext).length > 0) urlSearch.aiSearchContext = searchContext;
        return urlSearch;
    }

    //below for normal url decode
    const isTitleOrType = (val, target) => { //handle different checking for auction and properties
        if (target === global.AUCTION) return seoComponent.isTitleV2(val, auctionTitleOptions);
        return SetupData.SEOTitleOrType.includes(val);
    }
    const decodeTitleOrType = (val) => {
        if (target === global.AUCTION || seoComponent.isTitleV2(val, titleOptions, queryParams)) {
            urlSearch.titleOrType = val;
        } else { //handle selected type.length = 1
            const titleType = seoComponent.getTitleFromType(val, titleOptions);
            urlSearch = {
                ...urlSearch,
                ...titleType,
            }
        }
    }
    const decodeState = (val) => {
        urlSearch.state = seoComponent.beautifyState(val);
    }
    const decodeArea = (val) => {
        urlSearch.area = seoComponent.beautifyArea(val);
    }
    const decodeQueryParams = (queryParamsObj) => {
        const noDecodeKeys = ["auction_start", "auction_end", "types"]; //fields that does not need decodeURI

        let queryParamSearch = {};

        Object.keys(queryParamsObj).forEach(key => {
            let searchKey = key;
            let searchVal = String(queryParamsObj[key]);
            let decode = true;
            if (key === "query") searchKey = "freeText";
            else if (key === "min_price") searchKey = "minPrice";
            else if (key === "max_price") searchKey = "maxPrice";
            else if (key === "property") searchKey = "propertyName";
            else if (key === "min_buildup") searchKey = "minBuildup";
            else if (key === "max_buildup") searchKey = "maxBuildup";
            else if (key === "min_landarea") searchKey = "minLandArea";
            else if (key === "max_landarea") searchKey = "maxLandArea";
            else if (key === "master") searchKey = "realestateMaster";
            else if (key === "auction_start") searchKey = "auctionStart";
            else if (key === "auction_end") searchKey = "auctionEnd";
            else if (key === "page") searchKey = "page";

            if (noDecodeKeys.includes(key)) decode = false;

            if (decode) searchVal = seoComponent.decodeQueryParam(searchVal);

            if (key === "types") {
                const typesArr = searchVal.split(',');
                searchVal = typesArr.map(type => seoComponent.beautifyTitleOrType(type));
            }
            else if (key !== "query") searchVal = string.capitalize(searchVal);

            queryParamSearch[searchKey] = searchVal;
        });
        return queryParamSearch;
    }

    if (state_or_title_or_type) {
        //also handle for auction title checking, pass in target for checking
        if (isTitleOrType(state_or_title_or_type, target)) decodeTitleOrType(state_or_title_or_type);
        else decodeState(state_or_title_or_type);
    }

    if (state) decodeState(state);

    if (area_or_title_or_type) {
        if (isTitleOrType(area_or_title_or_type)) decodeTitleOrType(area_or_title_or_type);
        else decodeArea(area_or_title_or_type);
    }

    if (area) decodeArea(area);

    if (title_or_type) decodeTitleOrType(title_or_type);

    const decodedQP = decodeQueryParams(queryParams);

    const finalUrlSearch = {
        ...urlSearch,
        ...decodedQP,
    }

    return finalUrlSearch;
};