import React from 'react';
import "../styles/components/modal.scss";
import { MdClose } from 'react-icons/md';
import { Button } from 'reactstrap';

export class CustomModal extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            open: props.show || false,
        }
    }

    componentDidMount() {
        if (this.state.open)
            document.body.style.overflow = 'hidden';
    }

    componentDidUpdate(prevProps) {
        if (prevProps.show !== this.props.show) {
            this.setState({ open: this.props.show });
            document.body.style.overflow = this.props.show ? 'hidden' : 'unset';
        }
    }

    componentWillUnmount() {
        document.body.style.overflow = 'unset';
    }

    render() {
        const { modalName, toggle, title } = this.props;

        
        const handleModalClose = () => {
            const modal = document.querySelector('.custom-modal');
            if (modal?.classList) {
                modal.classList.add('fade-out');
                setTimeout(() => {
                    modal.classList.remove('fade-out');
                    toggle();
                    modal.style.display = 'none';
                }, 300);
            }
        };

        return (
            <React.Fragment>
                {this.state.open ?
                    <div
                        id={modalName}
                        className="custom-modal modal"
                        style={{ display: "block" }}
                        onClick={() => {
                            handleModalClose();
                        }}
                    >
                        <div className="custom-modal-content modal-content" onClick={(e) => e.stopPropagation()}>
                            <div className="custom-modal-header modal-header">
                                <h2 className='custom-modal-title' style={{ margin: 0 }}>{title}</h2>
                                <Button className='custom-modal-close-btn modal-close-btn' onClick={() => { handleModalClose(); }}><MdClose /></Button>
                            </div>
                            {this.props.children}
                        </div>

                    </div>
                    : null}
            </React.Fragment>
        )
    }
};

export const CustomModalBody = ({ children }) => {
    return (
        <div className="custom-modal-body modal-body">
            {children}
        </div>
    );
};

export const CustomModalFooter = ({ children }) => {
    return (
        <div className="custom-modal-footer modal-footer">
            {children}
        </div>
    );
};