import React, { Fragment, useEffect, useState } from "react";

// CSS Framework
import { Container, Col, Row } from "react-bootstrap";

// Components
import Header2 from "../components/Layout/Header2";
import Footer2 from "../components/Layout/Footer2";
import SiteMap from "../businesses/SiteMap";

// Library
import seoComponent from "../businesses/seoComponent";
import lib from "../businesses/lib";

// Styles
import "../styles/pages/siteMap.scss";
import clsx from "clsx";
import { useMediaQuery } from "@material-ui/core";

const generalItems = [
  {
    title: "About NextSix Malaysia",
    items: [
      {
        title: "About Us",
        url: "/about",
      },
      {
        title: "Contact Us",
        url: "/contact",
      },
    ],
  },
  {
    title: "Mobile Application",
    items: [
      {
        title: "Property App for Android",
        url: "https://play.google.com/store/apps/details?id=com.nextsix.property",
      },
      {
        title: "Agent App for Android",
        url: "https://play.google.com/store/apps/details?id=com.nextsix.proforma&hl=en",
      },
      {
        title: "Property App for iOS",
        url: "https://apps.apple.com/my/app/nextsix/id1500782680",
      },
      {
        title: "Agent App for iOS",
        url: "https://apps.apple.com/my/app/nextsix-agent/id1500782720",
      },
    ],
  },
  {
    title: "Combine",
    items: [
      {
        title: "For Agent",
        items: [
          {
            title: "Agent LogIn",
            url: process.env.REACT_APP_SIGN_IN_URL,
          },
          {
            title: "Agent SignUp",
            url: "/signup",
          },
        ],
      },
      {
        title: "For Merchant",
        items: [
          {
            title: "Merchant SignUp",
            url: "/business",
          },
        ],
      },
    ],
  },
  {
    title: "Terms of Use",
    items: [
      {
        title: "Agent T&C",
        url: "/tnc/agent/index.html",
      },
      {
        title: "Privacy Policy",
        url: "/privacy-policy/en/index.html",
      },
    ],
  },
];

const blogItems = {
  title: "NextSix Blog",
  items: [
    {
      title: "All Blogs",
      url: "https://blog.nextsix.com/",
    },
    {
      title: "Property Handbook",
      url: "https://blog.nextsix.com/category/property-handbook/",
    },
    {
      title: "Agent Guide 101",
      url: "https://blog.nextsix.com/category/agent-guide-101/",
    },
    {
      title: "Lifestyle",
      url: "https://blog.nextsix.com/category/lifestyle/",
    },
  ],
};

const calculatorItems = {
  title: "NextSix Calculator",
  items: [
    {
      title: "Home Loan Analyzer",
      url: "/mortgage-calculator",
    },
  ],
};

const Content = ({ items }) => {
  const isSmallScreen = useMediaQuery("((max-width:767px)");

  return (
    <div className="container sitemap-container">
      {items.map((section, index) => (
        <section className="sitemap-section" key={index}>
          {index === 0 ? (
            <h1 className="sitemap-big-title">{section.title}</h1>
          ) : (
            <h2 className="sitemap-big-title2">{section.title}</h2>
          )}
          <Container className="sitemap-inner-container">
            <Row>
              {section.itemList.map((part, index) => {
                return (
                  <Fragment key={index}>
                    {part.title === "Combine" ? (
                      <Col key={index} sm={12} md={3} className="sitemap-col">
                        {part.items.map((subItem, index) => (
                          <Row key={index} className="sitemap-row-combined">
                            <Col className="sitemap-col-combined">
                              <h3 className="sitemap-h6">
                                {subItem.title || "\u00A0"}
                              </h3>
                              {subItem.items.map((item, index) => (
                                <h4 className="sitemap-li" key={index}>
                                  <a className="sitemap-li-a" href={item.url}>
                                    {item.title}
                                  </a>
                                </h4>
                              ))}
                            </Col>
                          </Row>
                        ))}
                      </Col>
                    ) : (
                      <Col
                        className="sitemap-col"
                        key={index}
                        sm={12}
                        md={3}
                        style={{
                          marginBottom: clsx(
                            isSmallScreen && section.itemList[index + 1]
                              ? section.itemList[index + 1].title !== ""
                                ? "40px"
                                : "10px"
                              : ""
                          ),
                        }}
                      >
                        <h3
                          className="sitemap-h6"
                          style={{
                            display: clsx(
                              isSmallScreen && part.title === ""
                                ? "none"
                                : "block"
                            ),
                            height: clsx(isSmallScreen ? "auto" : "26px"),
                            marginBottom: clsx(isSmallScreen ? "0" : ".5rem"),
                          }}
                        >
                          {part.title || "\u00A0"}
                        </h3>
                        {part.items.map((item, index) => (
                          <h4 className="sitemap-li" key={index}>
                            <a className="sitemap-li-a" href={item.url}>
                              {item.title}
                            </a>
                          </h4>
                        ))}
                      </Col>
                    )}
                  </Fragment>
                );
              })}
            </Row>
          </Container>
        </section>
      ))}
    </div>
  );
};

const restructureAgentData = (data) => {
  let indexCap = 0;
  let itemTrack = 0;

  const firstSec = [
    {
      title: "Discover Malaysia's Property Agents",
      items: [],
    },
    {
      title: "",
      items: [],
    },
    {
      title: "",
      items: [],
    },
    {
      title: "",
      items: [],
    },
  ];

  const baseUrl = (state) => {
    return `/agent/${
      state ? seoComponent.treatState(state) : "all-states"
    }/all-areas/all-types`;
  };
  for (const [index, state] of data.entries()) {
    if (index === 0) {
      firstSec[indexCap].items.push({
        title: `Find an Agent`,
        url: baseUrl(),
      });
      itemTrack++;
    }
    firstSec[indexCap].items.push({
      title: `${state} Agents`,
      url: baseUrl(state),
    });
    itemTrack++;
    if (itemTrack === 5) {
      indexCap++;
      itemTrack = 0;
    }
  }

  return firstSec;
};

const restructurePopularPropertyData = (
  propertyTypes,
  states,
  areas,
  propertyFor
) => {
  let indexCap = 1;
  let itemTrack = 0;

  const baseUrl = `/properties-for-${
    propertyFor === "Rent" ? "rent" : "sale"
  }/`;

  const sectionData = [
    {
      title: `Properties For ${propertyFor}`,
      items: propertyTypes.map((item) => ({
        title: `${item}` + " for " + `${propertyFor}`,
        url: baseUrl + seoComponent.treatTitleOrType(item.toLowerCase()),
      })),
    },
    {
      title: `Popular Properties For ${propertyFor}`,
      items: [],
    },
    {
      title: "",
      items: [],
    },
    {
      title: "",
      items: [],
    },
  ];

  for (const state of states) {
    sectionData[indexCap].items.push({
      title: `Properties for ${propertyFor} in ${state}`,
      url: baseUrl + seoComponent.treatState(state.toLowerCase()),
    });
    itemTrack++;
    if (itemTrack === 15) {
      indexCap++;
      itemTrack = 0;
    }
  }

  for (const area of areas) {
    const stateUrl = seoComponent.treatState(area.state.toLowerCase());
    const areaUrl =
      area.area === "Kuala Lumpur"
        ? ""
        : "/" + seoComponent.treatArea(area.area.toLowerCase());

    sectionData[indexCap].items.push({
      title: `Properties for ${propertyFor} in ${area.area}`,
      url: baseUrl + stateUrl + areaUrl,
    });

    itemTrack++;
    if (itemTrack === 15) {
      indexCap++;
      itemTrack = 0;
    }
  }
  return sectionData;
};

const restructureMasterPropertyData = (data) => {
  let indexCap = 0;
  let itemTrack = 0;

  const sectionData = [
    {
      title: "Featured New Properties",
      items: [],
    },
    {
      title: "",
      items: [],
    },
    {
      title: "",
      items: [],
    },
    {
      title: "",
      items: [],
    },
  ];

  for (const master of data) {
    sectionData[indexCap].items.push({
      title: lib.toTitleCase(master.title),
      url: seoComponent.createSearchPropertyUrl({
        realestateMaster: master.masterId,
      }),
    });
    itemTrack++;
    if (itemTrack === 15) {
      indexCap++;
      itemTrack = 0;
    }
  }

  if (sectionData[1].items.length === 0) sectionData[1] = blogItems;
  else sectionData[2] = blogItems;

  if (sectionData[2].items.length === 0) sectionData[2] = calculatorItems;
  else sectionData[3] = calculatorItems;

  return sectionData;
};

const SiteMapPage = () => {
  const [siteMapData, setSiteMapData] = useState([]);

  const fetchData = async () => {
    const data = await SiteMap.siteMap();
    const restructureData = [
      {
        title: "NextSix Malaysia Sitemap",
        itemList: [...generalItems],
      },
      {
        title: "Malaysia Agents",
        itemList: [...restructureAgentData(data.states)],
      },
      {
        title: "Properties For Buy",
        itemList: [
          ...restructurePopularPropertyData(
            data.propertiesTitle,
            data.states,
            data.popularSalesProperties,
            "Buy"
          ),
        ],
      },
      {
        title: "Properties For Rent",
        itemList: [
          ...restructurePopularPropertyData(
            data.propertiesTitle,
            data.states,
            data.popularRentProperties,
            "Rent"
          ),
        ],
      },
      {
        title: "NextSix Malaysia Directories",
        itemList: [...restructureMasterPropertyData(data.propertyMasterList)],
      },
    ];

    setSiteMapData(restructureData);
  };

  useEffect(() => {
    fetchData();
  }, []);

  return (
    <>
      <Header2 />
      <section className="agentPage-empty2 container-fluid" />
      <Content items={siteMapData} />
      <Footer2 />
    </>
  );
};

export default SiteMapPage;
