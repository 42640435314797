import apibase from "./BaseAPI";

const nearbyAmenities = async (lat, lon, radius) => {
    const params = {
        lat,
        lon,
        radius,
    };
    const url = '/amenity/nearby';
    try {
        const res = await apibase.post(url, params);
        return res.data;
    } catch (err) {
        throw err;
    }
}

export default {
    nearbyAmenities,
}