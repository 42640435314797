//only include static schema markups
import React from "react";
import SchemaMarkup from "./SchemaMarkup";
import { HomeRevampImgs } from "../../img";

export default function HomeMarkupList() {
    const domainName = process.env.REACT_APP_WEBSITE;
    
    const creator = {
        "@type": "Organization",
        "@id": `${domainName}/#Organization`,
        "url": `${domainName}`,
        "name": "NEXTSIX"
    };

    const imgGeneral = {
        "acquireLicensePage": `${domainName}`,
        "license": `${domainName}`,
        "copyrightNotice": "Created by NEXTSIX",
        "inLanguage": "en-MY",
        "creator": creator
    }

    const homepage = JSON.stringify({
        "@context": "https://schema.org",
        "@graph": [
            {
                "@type": "LocalBusiness",
                "name": "Property Website in Malaysia for Buy, Sell or Rent | Nextsix",
                "description": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                "url": `${domainName}`,
                "logo": `${domainName}/static/media/logoNextSixBlack.0ca13b8b.svg`,
                "address": "Blk C, 3, 7, Jalan Kuchai Maju 13, Kuchai Exchange, 58200 Wilayah Persekutuan, Wilayah Persekutuan Kuala Lumpur",
                "email": "hello@nextsix.com",
                "image": [
                    {
                        "@type": "ImageObject",
                        "url": `${domainName}${HomeRevampImgs.SearchSectionBg}`,
                        "caption": "GPS Your Agent: Search for your preferred property in ease with the right agent - NEXTSIX",
                        "creditText": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                        ...imgGeneral
                    }, {
                        "@type": "ImageObject",
                        "url": "https://iprofstore.blob.core.windows.net/iklan/640abd05a3b5c10052a5a801/iklan.png",
                        "caption": "Weekly contest from NEXTSIX you can't afford to miss - NEXTSIX",
                        "creditText": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                        ...imgGeneral
                    }, {
                        "@type": "ImageObject",
                        "url": "https://iprofstore.blob.core.windows.net/iklan/640abc07a3b5c10052a5a5f5/iklan.png",
                        "caption": "Property Agent: Maximise your listing exposure - NEXTSIX",
                        "creditText": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                        ...imgGeneral
                    }, {
                        "@type": "ImageObject",
                        "url": `${domainName}${HomeRevampImgs.WhatsYourGPS}`,
                        "caption": "NEXTSIX Mobile Application: GPS Agent - NEXTSIX",
                        "creditText": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                        ...imgGeneral
                    }, {
                        "@type": "ImageObject",
                        // "url": `${domainName}/static/media/mortgage-calculator.16b39cc3.png`,
                        "url": `${domainName}${HomeRevampImgs.LoanAnalyzer}`,
                        "caption": "NEXTSIX Home Loan Analyzer: Free calculator for property loan - NEXTSIX",
                        "creditText": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                        ...imgGeneral
                    }

                ],
                "brand": [
                    {
                        "@id": `${domainName}/#Organization`
                    }, {
                        ...creator,
                        "legalName": "NEXTSIX",
                        "mainEntityOfPage": `${domainName}`,
                        "logo": `${domainName}/static/media/logoNextSixBlack.0ca13b8b.svg`,
                        "address": "Blk C-3-7, Kuchai Exchange No 43, Jalan Kuchai Maju 13, Off Jalan Kuchai Lama, 58200 Kuala Lumpur",
                        "areaServed": "Malaysia",
                        "email": "hello@nextsix.com"
                    }
                ]
            }, {
                "@type": "Website",
                "url": `${domainName}`,
                "name": "Property Website in Malaysia for Buy, Sell or Rent | Nextsix",
                "description": "Check out our property website in Malaysia to buy, sell or rent now. Most suitable for home buyers, renters & investors looking for realtors or properties",
                "headline": "Let Us Find You A Perfect Home",
                "inLanguage": "en-MY",
                "publisher": {
                    ...creator,
                    "mainEntityOfPage": `${domainName}`,
                    "logo": `${domainName}/static/media/logoNextSixBlack.0ca13b8b.svg`,
                    "sameAs": [
                        "https://www.facebook.com/NextsixMY/",
                        "https://www.instagram.com/nextsixmy/",
                        "https://www.linkedin.com/company/nextsix?trk=public_jobs_topcard-org-name"
                    ],
                    "contactPoint": {
                        "@type": "ContactPoint",
                        "name": "NEXTSIX",
                        "areaServed": "Malaysia"
                    }
                }
            }
        ]
    })

    return (
        <>
            <SchemaMarkup>{homepage}</SchemaMarkup>
        </>
    )
}